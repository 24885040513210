var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"header bg-white border-bottom"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"header-body border-0"},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col"},[_c('h6',{staticClass:"header-pretitle"},[_vm._v("Details")]),_c('h1',{staticClass:"header-title"},[_vm._v(" Batch Booking Request - "+_vm._s(_vm.batchRequestID)+" ")])])])])])]),_c('div',{staticClass:"container-fluid"},[_c('div',[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[(!_vm.loading)?_c('router-link',{staticClass:"btn btn-light mb-4 btn-sm",attrs:{"to":{ name: 'ListBatchBookings' }}},[_vm._v("Go back")]):_vm._e(),_c('div',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h2',[_vm._v("Request")]),(_vm.batchRequest)?_c('table',{staticClass:"table"},[_c('tr',[_c('td',[_vm._v("Created At")]),_c('td',[_vm._v(_vm._s(_vm.batchRequest.created_at))])]),_c('tr',[_c('td',[_vm._v("Processing Status")]),_c('td',[_c('div',{staticClass:"badge",class:{
																'badge-success':
																	_vm.batchRequest?.processing_completed_at,
																'badge-warning':
																	!_vm.batchRequest?.processing_completed_at,
															}},[_vm._v(" "+_vm._s(_vm.batchRequest?.processing_completed_at ? 'processed' : 'pending')+" ")])])]),_c('tr',[_c('td',[_vm._v("Start Date")]),_c('td',[_vm._v(_vm._s(_vm.batchRequest.booking_data.start_date))])]),_c('tr',[_c('td',[_vm._v("End Date")]),_c('td',[_vm._v(_vm._s(_vm.batchRequest.booking_data.end_date))])]),_c('tr',[_c('td',[_vm._v("Payment Source")]),_c('td',[_vm._v(" "+_vm._s(_vm.batchRequest.booking_data.payment_source)+" ")])]),(
														_vm.batchRequest.booking_data.meta &&
															_vm.batchRequest.booking_data.meta.pickup
													)?_c('tr',[_c('td',[_vm._v("Pickup")]),_c('td',[_vm._v(_vm._s(_vm.batchRequest.booking_data.meta.pickup))])]):_vm._e(),(
														_vm.batchRequest.booking_data.meta &&
															_vm.batchRequest.booking_data.meta.destination
													)?_c('tr',[_c('td',[_vm._v("Dropoff")]),_c('td',[_vm._v(" "+_vm._s(_vm.batchRequest.booking_data.meta.destination)+" ")])]):_vm._e(),(
														_vm.batchRequest.booking_data.meta &&
															_vm.batchRequest.booking_data.meta.route_code
													)?_c('tr',[_c('td',[_vm._v("Route Code")]),_c('td',[_vm._v(" "+_vm._s(_vm.batchRequest.booking_data.meta.route_code)+" ")])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Route Itinerary")]),_c('td',[_vm._v(_vm._s(_vm.batchRequest.booking_data.itinerary_id))])])]):_vm._e()])])]),_c('div',{staticClass:"col-7"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(!_vm.batchRequest?.processing_completed_at)?_c('div',[_vm._m(0)]):_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h2',[_vm._v("Response")]),_c('div',{staticClass:"col-auto d-flex align-items-end px-0"},[(_vm.batchRequest)?_c('download-button',{attrs:{"downloading":_vm.downloadingReport,"label":"Download Report"},on:{"download":function($event){return _vm.downloadReport()}}}):_vm._e()],1)]),(
														_vm.batchRequest.processing_result &&
															!_vm.batchRequest.processing_result.success
													)?_c('div',{staticClass:"alert alert-danger"},[_c('p',[_vm._v(" Encountered error while processing batch booking. ")]),_c('p',[_vm._v(_vm._s(_vm.batchRequest.processing_result.error))])]):_c('div',[_c('table',{staticClass:"table"},_vm._l((_vm.batchRequest
																.processing_result.data),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.userId)+" ")]),_c('td',[_c('div',{staticClass:"badge",class:{
																		'badge-success': item.success,
																		'badge-danger': !item.success,
																	}},[_vm._v(" "+_vm._s(item.success ? 'completed' : 'failed')+" ")])]),_c('td',[(item.error)?_c('span',{attrs:{"title":item.error},on:{"click":function($event){$event.preventDefault();return _vm.showDetailsOfError(item.error)}}},[_c('span',{staticClass:"fa fa-info"}),_vm._v(" details")]):_vm._e()])])}),0)])])])])])])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-5"},[_c('span',{staticClass:"fa fa-spin fa-spinner"})])
}]

export { render, staticRenderFns }