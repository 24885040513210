<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Details</h6>
							<h1 class="header-title">
								Batch Booking Request - {{ batchRequestID }}
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div>
				<div class="row mt-4">
					<div class="col-12">
						<router-link
							:to="{ name: 'ListBatchBookings' }"
							class="btn btn-light mb-4 btn-sm"
							v-if="!loading"
							>Go back</router-link
							>

						<div class="">
							<div class="row">
								<div class="col-4">
									<div class="card">
										<div class="card-body">
											<h2>Request</h2>
											<table class="table" v-if="batchRequest">
												<tr>
													<td>Created At</td>
													<td>{{ batchRequest.created_at }}</td>
												</tr>
												<tr>
													<td>Processing Status</td>
													<td>
														<div
															class="badge"
															:class="{
																'badge-success':
																	batchRequest?.processing_completed_at,
																'badge-warning':
																	!batchRequest?.processing_completed_at,
															}"
															>
															{{
																batchRequest?.processing_completed_at
																	? 'processed'
																	: 'pending'
															}}
														</div>
													</td>
												</tr>

												<tr>
													<td>Start Date</td>
													<td>{{ batchRequest.booking_data.start_date }}</td>
												</tr>
												<tr>
													<td>End Date</td>
													<td>{{ batchRequest.booking_data.end_date }}</td>
												</tr>
												<tr>
													<td>Payment Source</td>
													<td>
														{{ batchRequest.booking_data.payment_source }}
													</td>
												</tr>
												<tr
													v-if="
														batchRequest.booking_data.meta &&
															batchRequest.booking_data.meta.pickup
													"
													>
													<td>Pickup</td>
													<td>{{ batchRequest.booking_data.meta.pickup }}</td>
												</tr>

												<tr
													v-if="
														batchRequest.booking_data.meta &&
															batchRequest.booking_data.meta.destination
													"
													>
													<td>Dropoff</td>
													<td>
														{{ batchRequest.booking_data.meta.destination }}
													</td>
												</tr>

												<tr
													v-if="
														batchRequest.booking_data.meta &&
															batchRequest.booking_data.meta.route_code
													"
													>
													<td>Route Code</td>
													<td>
														{{ batchRequest.booking_data.meta.route_code }}
													</td>
												</tr>
												<tr>
													<td>Route Itinerary</td>
													<td>{{ batchRequest.booking_data.itinerary_id }}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>

								<div class="col-7">
									<div class="card">
										<div class="card-body">
											<div v-if="!batchRequest?.processing_completed_at">
												<div class="p-5">
													<span class="fa fa-spin fa-spinner"></span>
												</div>
											</div>
											<div v-else>
												<div
													class="
                            d-flex
                            justify-content-between
                            align-items-center
                          "
													>
													<h2>Response</h2>
													<div class="col-auto d-flex align-items-end px-0">
														<download-button
															v-if="batchRequest"
															:downloading="downloadingReport"
															label="Download Report"
															@download="downloadReport()"
															>
														</download-button>
													</div>
												</div>
												<div
													class="alert alert-danger"
													v-if="
														batchRequest.processing_result &&
															!batchRequest.processing_result.success
													"
													>
													<p>
														Encountered error while processing batch booking.
													</p>
													<p>{{ batchRequest.processing_result.error }}</p>
												</div>
												<div v-else>
													<table class="table">
														<tr
															v-for="(item, index) in batchRequest
																.processing_result.data"
															:key="index"
															>
															<td>
																{{ index + 1 }}
															</td>
															<td>
																{{ item.userId }}
															</td>
															<td>
																<div
																	class="badge"
																	:class="{
																		'badge-success': item.success,
																		'badge-danger': !item.success,
																	}"
																	>
																	{{ item.success ? 'completed' : 'failed' }}
																</div>
															</td>
															<td>
																<span
																	@click.prevent="
																		showDetailsOfError(item.error)
																	"
																	:title="item.error"
																	v-if="item.error"
																	><span class="fa fa-info"></span>
																	details</span
																	>
															</td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DownloadButton from '@/components/core/DownloadButton'
import { ExportToCsv } from 'export-to-csv'
export default {
  components: { DownloadButton },
  props: {
    batchRequestID: [Number, String]
  },
  data () {
    return {
      fetchingbatchRequest: false,
      batchRequest: null,
      downloadingReport: false,
      batchBokings: [],
      loading: false
    }
  },
  mounted () {
    this.fetchBatchRequest()
  },
  watch: {
    batchRequestID () {
      this.fetchBatchRequest()
    }
  },
  methods: {
    showDetailsOfError (msg) {
      this.$swal({
        icon: 'error',
        title: 'Opps..',
        text: msg,
        showCloseButton: true
      })
    },
    fetchBatchRequest () {
      this.fetchingbatchRequest = true
      this.axios
        .get(`/v1/batch-bookings/${this.batchRequestID}`)
        .then((res) => {
          const batchRequest = res.data
          this.batchBokings = batchRequest.processing_result.data
          if (batchRequest.booking_data && batchRequest.booking_data.meta) {
            try {
              batchRequest.booking_data.meta = JSON.parse(
                batchRequest.booking_data.meta
              )
            } catch (e) {
              (e)
            }
          }
          this.batchRequest = batchRequest

          if (!batchRequest?.processing_completed_at) {
            setTimeout(() => {
              this.fetchBatchRequest()
            }, 2000)
          }
        })
        .catch((err) => {
          (err)
          this.$swal({
            icon: 'error',
            title: 'Batch booking request',
            text: 'Could not fetch Batch booking request',
            showCloseButton: true
          })
        })
        .finally(() => (this.fetchingbatchRequest = false))
    },
    async downloadReport () {
      this.downloadingReport = true
      const csvData = this.batchBokings.map((data) => {
        return {
          email: data.userId,
          status: data.success == true ? 'Completed' : 'Failed',
          resason: data.error ? data.error : 'N/A'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: 'Batch booking List',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: 'Batch booking List',
        useTextFile: false,
        useBom: true,
        headers: ['Email Address', 'Status', 'Reason']
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)

      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    }
  }
}
</script>

<style scoped></style>
